@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
  & textarea {
    min-height: 80px;
    max-height: 140px;
    overflow: auto;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.buttonsGroup {
  display: flex;
  gap: 20px;
}

.closeBtn {
  border-radius: 6px;
  border: 1px solid var(--marketplaceColorDark);
  background: #fff;
  color: var(--marketplaceColorDark);
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.267px;
  &:hover {
    background: #fff;
  }
}

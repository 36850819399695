@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  overflow: auto;
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;
}

:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    border-radius: 8px;
    border: 3px solid var(--marketplaceColorDark);
    padding: 12px;
  }
}

:global(.fullscreen) {
  background-color: var(--matterColorLight) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
  & > div {
    aspect-ratio: 16/16;
  }
}

.noImage {
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: 4px;
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */

  /* Colors */
  border-radius: 2px;
  border: 1px solid var(--marketplaceColorDark);
  background: #fff;
  min-height: 31px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  transition: var(--transitionStyleButton);
  padding: 0 12px;

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    /* background-color: var(--marketplaceColor); */
    border-color: var(--marketplaceColor);
    /* color: var(--matterColorLight); */
  }

  /* @media (--viewportLarge) {
    display: block;
  } */
}

.imagesButtons {
  position: absolute;
  /* bottom: 19px; */
  top: 600px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 9;
  @media (max-width: 767px) {
    top: 300px;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorDark);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.listingImagesSlider {
  position: relative;
  overflow: hidden;

  & :global(.slick-initialized) {
  }
  &.sneakerBorder {
    & :global(.slick-list) {
      border: 2px solid #d7282f;
    }
  }
  &.streetwearBorder {
    & :global(.slick-list) {
      border: 2px solid #e87200;
    }
  }
  &.vintageBorder {
    & :global(.slick-list) {
      border: 2px solid #61a60e;
    }
  }
  & :global(.slick-list) {
    border: 3px solid var(--marketplaceColorDark);
    border-radius: 5px;
    padding: 12px;
    text-align: center;
    @media (max-width: 576px) {
      padding: 4px;
    }
  }
  & :global(.slick-slide) {
    text-align: center;
    & img {
      width: 95%;
      object-fit: contain;
      object-position: center;
      max-height: 660px;
      @media (--viewportMedium) {
        min-height: 613px;
      }
      @media (max-width: 767px) {
        max-height: 324px;
        min-height: 324px;
      }
    }
  }

  & :global(.slick-dots) {
    display: flex !important;
    bottom: unset !important;
    position: relative !important;
    max-width: 660px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 20px;
    padding-bottom: 15px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: var(--marketplaceColor);
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--marketplaceColorDark);
    }
    & > li {
      flex: 1 1;
      overflow: hidden;
      min-height: 135px;
      min-width: 135px;

      & img {
        object-fit: cover;
        min-height: 135px;
        min-width: 135px;
        width: 100%;
        max-height: 135px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0 16px;
    margin-top: 43px;
  }
}
.sliderVideoThumbnail {
  width: 100%;
  height: auto;
  @media (--viewportMedium) {
    min-height: 135px;
    object-fit: cover;
  }
}

.sliderVideo {
  max-width: 95%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  max-height: 660px;
  display: flex;
  @media (--viewportMedium) {
    min-height: 550px;
  }

  @media (max-width: 767px) {
    max-height: 324px;
    min-height: 324px;
  }
}

@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  /* composes: marketplaceModalTitleStyles from global; */
  font-weight: 300;
  color: #000;
  font-size: 22px;
  padding-bottom: 14px;
  border-bottom: 1px solid #8e8e8e;
  margin: 0;
  @media (max-width: 1023px) {
    max-width: 90%;
  }
}

.errorField {
  & input {
    border-radius: 4px;
    border: 0.5px solid var(--primary-colors-primary-red-sneaker-store, #d7282f);
    background: var(--Light-Red, #f3e6e7);
  }
}

.inputError {
  color: #d7282f;
  text-align: right;
  font-family: Avenir;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 12px;
  transform: translateY(-16px);
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 0px 0 24px 0;
  }
}
.text {
  border: #3065a1 1px solid;
  font-family: 'Inter';
  display: block;
  width: 100%;
  margin: 0;
  padding: 9px 16px 9px 16px;
  font-size: 14px;
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-weight: var(--fontWeightMedium);
  border: 0.5px solid #474747;
  border-radius: 4px;
  line-height: 20px;
}
.label {
  font-family: 'Inter', Arial, sans-serif;
  display: block;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
.sliderVideo {
  width: 95%;
  object-fit: contain;
  object-position: center;
  max-height: 660px;
  @media (max-width: 767px) {
    max-height: 324px;
    min-height: 324px;
  }
}

.listingImagesSlider {
  position: relative;
  overflow: hidden;
  & :global(.slick-list) {
    border: 3px solid #3065a1;
    border-radius: 5px;
    padding: 12px;
    text-align: center;
    @media (max-width: 576px) {
      padding: 4px;
    }
  }
  & :global(.slick-slide) {
    text-align: center;
    & img {
      width: 95%;
      object-fit: contain;
      object-position: center;
      max-height: 660px;
      @media (--viewportMedium) {
        min-height: 613px;
      }
      @media (max-width: 767px) {
        max-height: 324px;
        min-height: 324px;
      }
    }
  }

  & :global(.slick-dots) {
    display: flex !important;
    bottom: unset !important;
    position: relative !important;
    max-width: 660px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 20px;
    padding-bottom: 15px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #478cbc80;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #3065a1;
    }
    & > li {
      flex: 1 1;
      overflow: hidden;
      min-height: 135px;
      min-width: 135px;

      & img {
        object-fit: cover;
        min-height: 135px;
        min-width: 135px;
        width: 100%;
        max-height: 135px;
      }
    }
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
  & > div {
    flex: 1;
  }
  @media (--viewportMedium) {
    flex-direction: row;
  }
  & label {
    margin-bottom: 5px;
  }
  & .inputPrice {
    & .label {
      margin-bottom: 5px;
    }
    & .text {
      border: 0.5px solid #474747;
      border-radius: 4px;
      background: #f1f0f0;
      height: 40px;
      color: var(--marketplaceColorDark);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
    }
  }
  & .input {
    & input {
      border: 0.5px solid #474747;
    }
  }
}

.listingDetails {
  display: flex;
  gap: 14px;
  margin-top: 36px;
  margin-bottom: 28px;
  & .listingImagesSlider {
    min-width: 124px;
    height: 124px;
    & img {
      width: 124px;
      height: 124px;
      object-fit: cover;
    }
    & video {
      width: 124px;
      height: 124px;
      object-fit: cover;
    }
  }

  & .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
  }
  & .priceValue {
    color: var(--marketplaceColorDark);
    font-size: 14px;
    line-height: 22px;
    font-weight: 800;
  }
  & .listingProfile {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-top: 6px;
  }
  & .providerAvatar {
    width: 45px;
    height: 45px;
  }
  & .offerFor {
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    color: #000;
    display: block;
    line-height: 1;
  }
  & .offerForAuthorName {
    font-weight: 800;
    font-size: 12px;
    color: #000;
    display: block;
    line-height: 1;
    margin-top: 4px;
  }
}

.submitButton {
  background-color: #000;
  &:disabled {
    background-color: #8e8e8e;
  }
}

.smallText {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.2px;
  display: block;
  width: 100%;
  margin-bottom: 11px;
}

.sendCodeBtn {
  width: max-content;
  margin-left: auto;
  min-height: 44px;
  min-width: 158px;
  &:disabled {
    background-color: #8e8e8e;
  }
}

.resendCodeText {
  margin: 0 0 30px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.resedText {
  color: var(--marketplaceColorDark);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration-line: underline;
}
